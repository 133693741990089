.table-chk {
    margin-right: 0.5rem;
}

h1, h2, h3, h4, h5, h6 {
    margin-block: 0.5em;
}
.tac {
    text-align: center;
}
@import "./comps/table";
@import "./comps/input";
@import "./comps/containers";
@import "./comps/buttons";
