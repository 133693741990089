*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
}

:root {
  $primary: #FF715B;
  $primaryDark: darken($primary, 5);
  $secondary: #554488;
  $secondary: #1EA896;
  $secondaryDark: darken(#554488, 5);
  $accent: hsl(215, 97%, 57%);
  $accent: #1EA896;
  --border: hsl(60, 6%, 17%);
  --input-background: #D0D0D0;
  --background: #ebf1f9;
  --background-alt: #FAFAFA;
  --card-background: #FAFAFA;
  --input-background: #F0E0D0;
  --navbar-background: #{$primary};
  --primary-dark: #{$primaryDark};
  --primary: #{$primary};
  --primary-light: #{lighten($primary, 8)};
  --secondary-dark: #{$secondaryDark};
  --secondary: #{$secondary};
  --secondary-light: #{lighten($secondary, 8)};
  --accent-dark: #{darken($accent, 8)};
  --accent: #{$accent};
  --accent-light: #{lighten($accent, 8)};
  --fore-color: hsl(0, 89%, 6%);
  --white: hsl(0, 0%, 98%);
  --fore-dark: hsl(196, 19%, 12%);
  --fore-light: hsl(196, 0%, 96%);

  $gray: #454545;
  --gray: #{$gray};
  --gray-dark: #{darken($gray, 8)};

  $danger: hsl(9, 70%, 45%);
  --danger: #{$danger};
  --danger-dark: #{darken($danger, 8)};

  $valid: hsl(138, 24%, 55%);
  --valid: #{$valid};
  --valid-dark: #{darken($valid, 8)};

  --toast-background: #{$gray};
  --toast-info-foreground: #{$gray};
  --toast-info-background: #{lighten($gray, 45)};
  --toast-error-foreground: #{$danger};
  --toast-error-background: #{lighten($danger, 45)};
  --toast-success-foreground: #{$valid};
  --toast-success-background: #{lighten($valid, 45)};
}

body {
  min-height: 100vh;
  background-color: var(--background);
  color: var(--fore-color);
}

.view {
  padding: 1rem;
  min-height: calc(100vh - 4rem);
  position: relative;
}

.loader {
  display: grid;
  place-items: center;
  img {
    margin-bottom: 1rem;
  }
}

.img {
  max-width: 100%;
}

ul, ol {
  list-style-type: none;
  margin-left: 1.25rem;
}

.floating-logo {
  bottom: -3rem;
  filter: blur(3px);
  max-width: min(600px, 60vw);
  opacity: .15;
  position: absolute;
  right: -3rem;
  transform: rotate(-12.5deg);
  z-index: -1;
}
