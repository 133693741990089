.items-row {
  --drag-space: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 3rem;
  gap: 0.25rem;
  margin-bottom: 0.25rem;

  input {
    margin: 0;
  }

  &.inserting-before:not(.dragging) {
    margin-top: var(--drag-space);
  }

  &.dragging+.inserting-before {
    margin-top: 0;
  }

  &.inserting-after:not(.dragging) {
    margin-bottom: var(--drag-space);
  }

  &.dragging {
    opacity: .5;
  }
}

button {
  display: grid;
  place-items: center;
}
