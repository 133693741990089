.toast-container {
    position: fixed;
    top: 1rem;
    left: 50%;
    max-height: 30vh;
    overflow-y: auto;
    transform: translateX(-50%);
    width: min(90vw, 400px);
    z-index: 200;

    &:empty {
        display: none;
    }
}

.toast {
    background-color: var(--toast-background);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    position: relative;
    text-align: left;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    &.toast--info {
        background-color: var(--toast-info-background);
        border: 1px solid var(--toast-info-foreground);
        color: var(--toast-info-foreground);
    }

    &.toast--error {
        background-color: var(--toast-error-background);
        border: 1px solid var(--toast-error-foreground);
        color: var(--toast-error-foreground);
    }

    &.toast--success {
        background-color: var(--toast-success-background);
        border: 1px solid var(--toast-success-foreground);
        color: var(--toast-success-foreground);
    }

    .toast-close {
        background: transparent;
        border: none;
        color: inherit;
        font-size: 1.125rem;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }
}