.recipes {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: 100%;
    margin-bottom: 4rem;

    @media screen and (min-width: 960px) {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }

    &.empty {
        grid-template-columns: 1fr;
    }
}

.message {
    width: 100%;
    text-align: center;
}
