.search-input-container {
    display: grid;
    place-items: center;
    padding: 1rem 0;
    padding-top: 0.5rem;
}

.search-input {
    background-color: var(--input-background);
    border: none;
    border-radius: 0.5rem;
    color: var(--fore-dark);
    margin-bottom: 0.5rem;
    outline: none;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    width: min(90vw, 500px);
}
