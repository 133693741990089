.input-group {
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
        margin-top: 1rem;
    }

    label {
        display: block;
    }
}

.input-text {
    background-color: var(--input-background);
    border: none;
    border-radius: 4px;
    color: var(--fore-color);
    margin-bottom: 0.5rem;
    outline: none;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
}
