.btn {
    background: var(--gray);
    border: 1px solid var(--gray);
    border-radius: 4px;
    color: var(--fore-light);
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    text-decoration: none;
    transition: background-color .2s ease;

    &.btn--wide {
        display: inline-block;
        text-align: center;
        width: 100%;
    }

    &:hover {
        background: var(--gray-dark);
        border-color: var(--gray-dark);
    }

    &.btn--primary {
        background: var(--primary);
        border-color: var(--primary);

        &:hover {
            background: var(--primary-dark);
        }
    }

    &.btn--secondary {
        background: var(--secondary);
        border-color: var(--secondary);

        &:hover {
            background: var(--secondary-dark);
        }
    }

    &.btn--accent {
        background: var(--accent);
        border-color: var(--accent);

        &:hover {
            background: var(--accent-dark);
        }
    }

    &.btn--valid {
        background: var(--valid);
        border-color: var(--valid);
        color: var(--fore-light);

        &:hover {
            background: var(--valid-dark);
        }
    }

    &.btn--danger {
        background: var(--danger);
        border-color: var(--danger);
        color: var(--fore-light);

        &:hover {
            background: var(--danger-dark);
        }
    }

    &:disabled, &:disabled:hover {
        background: var(--border);
        border-color: var(--border);
        cursor: not-allowed;
        opacity: 0.5;
    }
}
