.edit-recipe-form {
    max-width: 960px;
    margin-inline: auto;
}

.input-group-image {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 1rem;
    margin-bottom: 0.5rem;
    input {
        height: 100%;
    }
}

.input-image {
    max-width: 120px;
    max-height: 67.5px; // 16:9 aspect
    aspect-ratio: 16 / 9;
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}
