// @font-face {
//   font-family: 'Roboto';
//   src: url('/fonts/Roboto-Regular.ttf');
//   font-style: normal;
//   font-weight: normal;
// }

@font-face {
  font-family: 'Pacifico';
  src: url('/fonts/Pacifico-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Mukta';
  src: url('/fonts/Mukta-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Mukta';
  src: url('/fonts/Mukta-Bold.ttf');
  font-style: normal;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Mukta', sans-serif;
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: inherit;
}

@import "./theme/general";

.react-icons {
  vertical-align: middle;
}

.application-name {
  font-family: 'Pacifico', cursive;
  font-size: 2.5rem;
}
