.btn-new-recipe {
    align-items: center;
    background-color: var(--primary);
    bottom: 1rem;
    border-radius: 0.5rem;
    color: var(--fore-light);
    display: flex;
    gap: 0.5rem;
    height: 3rem;
    padding: 0.5rem 1rem;
    position: fixed;
    right: 1rem;
    text-decoration: none;
    transition: background-color .3s ease;
    z-index: 2000;

    &:hover {
        background-color: var(--primary-dark);
    }
}

.btn-new-recipe, .btn-new-recipe * {
    cursor: pointer;
}
