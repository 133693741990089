.unlock-container {
    display: grid;
    place-items: center;
    padding: 2rem;
}

.unlock-label {
    padding-bottom: 0.5rem;
}

.login-input {
    width: min(90vw, 400px);
}
