.navbar {
  background: var(--navbar-background);
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  height: 4rem;
  position: relative;
  z-index: 200;
}

.navbar-back {
  display: grid;
  place-items: center;
}

.back-icon {
  color: var(--fore-light);
  font-size: 1.2rem;
}

.navbar-middle {
  display: grid;
  place-items: center;
}

.navbar-logo {
  max-height: 2.5rem;
}
