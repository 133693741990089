.card-link {
    text-decoration: none;
}

.card, .card * {
    cursor: default;
}

.card {
    background-color: var(--card-background);
    border-radius: 1rem;
    box-shadow: 0 2px 0.875rem 0 rgba(0 0 0 / .15);
    display: grid;
    height: 100%;
    grid-template-columns: 60% 40%;
    min-height: 160px;
    overflow: hidden;
}

.card-header-title {
    color: var(--fore-color);
    font-size: 1.4rem;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
}

.card-content {
    padding: 1rem;
    color: var(--fore-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.card-ingredients {
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
  overflow: hidden;
}

.card-image-container {
  display: grid;
  place-items: center;
  padding: 1rem;
}

.card-image {
  border-radius: 0.75rem;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%
}

.card-tags {
  font-size: 0.8rem;
  margin-top: auto;
  flex-wrap: wrap;
}
