h1,
h2,
h3 {
  color: var(--primary);
}

.recipe-header {
  display: grid;
  grid-template-columns: 60% 40%;
  position: relative;
}

.recipe-header-image {
  border-radius: 1rem;
  box-shadow: 0 2px 0.875rem 0 rgba(0 0 0 / .15);
  position: sticky;
  grid-column: 2 / 3;
  grid-row: 1 / 1;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
  top: 3rem;
  width: 100%;
}

.recipe-header-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.recipe-header-description {
  margin-bottom: 1rem;
}

.recipe-body-block {
  margin-block: 1rem;
}

.recipe-body-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.25rem 0;
}

.tag-list {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.tag {
  background: var(--accent);
  color: var(--fore-light);
  display: inline-block;
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

.sml-msg {
  margin-top: 0.75rem;
}

.recipe-options {
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-block: 1rem;
}

.recipe-option {
  background: var(--primary);
  border: none;
  border-radius: 2rem;
  color: var(--white);
  display: grid;
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1;
  place-content: center;
  width: 3rem;
}
