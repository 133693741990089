.table {
    border: 1px solid var(--fore-color);
    border-collapse: collapse;
    max-width: 100%;
    min-width: 300px;

    td {
        padding: 0.25rem 0.5rem;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid var(--fore-color);
    }
}
